<template>
  <v-card flat class="pa-0">
    <!-- changing timer design for pwa -->
    <v-row no-gutters v-if="fieldsLoading || hoursList.length <= 0">
      <v-col v-for="n in 9" :key="n" cols="12" sm="6" lg="3">
       <v-sheet class="mt-2">
        <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
    <v-card flat class="timer--wrapper" v-else>
      <v-card-text class="pa-0">
        <v-container>
          <v-layout wrap align-center justify-left fill-height>
            <v-flex xs12 sm6>
              <v-form ref="weekNavigation">
                <v-container pa-0>
                  <v-layout wrap align-center justify-left row fill-height>
                    <v-flex xs3 sm3 md2 lg1 :class="$vuetify.breakpoint.smAndUp ? 'text-xs-right' : 'pl-8'">
                      <v-btn fab dark small color="secondary" @click="prevWeek">
                        <v-icon dark>mdi-chevron-left</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex xs2 sm3 md3 lg3 class="px-1">
                      <v-text-field :label="$t('message.timer.week')" :rules="weekValidation" @keypress.enter="goToCustomWeek" v-model="customWeek"></v-text-field>
                    </v-flex>
                    <v-flex xs3 sm3 md3 lg3 class="px-1">
                      <v-text-field :label="$t('message.timer.year')" :rules="yearValidation" @keypress.enter="goToCustomWeek" v-model="customYear"></v-text-field>
                    </v-flex>
                    <v-flex xs2 sm2 md2 lg2 :class="$vuetify.breakpoint.smAndUp ? 'px-6' :'px-2'">
                      <v-btn fab small dark color="secondary" @click="nextWeek">
                        <v-icon dark>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex xs2 sm1 md2 lg2>
                      <v-btn fab small dark color="secondary" @click="goToToday">
                        <v-icon dark>mdi-calendar</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-flex>
            <v-flex xs12 sm6>
              <v-layout justify-center>
               <!--  <v-btn class="ml-3" fab small dark color="secondary" @click="deliverHoursHandler">
                  <v-icon dark>mdi-check-circle-outline</v-icon>
                </v-btn>
                <v-spacer></v-spacer> -->
                <h2>{{$t('message.common.total')}} : {{ $formatter.replaceDotWithComma(this.totalHoursInWeek) }} <v-icon class="ml-2" color="primary" @click="deliverHoursHandler" >mdi-check-circle-outline</v-icon></h2>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-overlay v-if="loading & !fieldsLoading">
        <v-progress-circular
          indeterminate
          size="64"
          color="#f6b21b"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text class="pa-0" style="font-weight: 500;">
        <div class="timer_module">
          <table v-for="(item, index) in hoursList" :key="index" :class="item.is_week_end ? 'week_end_style' : ''">
            <tr @click="item.is_show = !item.is_show" :class="`${item.is_lock ? 'day-locked' : ''}`">
              <td width="2%"></td>
              <td width="15%">{{ item.short_day }}</td>
              <td width="23%">{{ $formatter.filterDate(item.date) }}</td>
              <td width="1%"></td>
              <td width="34%">{{ $formatter.replaceDotWithComma(item.totalInWork) }} ({{$formatter.replaceDotWithComma(item.totalInAbsense) }})t<!-- item.hours && item.hours.length > 0 ? getHoursSum(item.hours) : '00,00' --></td>
              <td width="10%">
                <v-icon v-if="item.statusName === 'NOT_DELIVERED'">mdi-send</v-icon>
                <v-icon color="#e0b517" v-if="item.statusName === 'DELIVERED'">mdi-timer-sand-empty</v-icon>
                <v-icon color="#ea1616" v-if="item.statusName === 'NOT_APPROVED'">mdi-information-outline</v-icon>
                <v-icon color="#41b883" v-if="item.statusName === 'APPROVED'">mdi-checkbox-marked-circle-outline</v-icon>
              </td>
              <td width="15%">
                <v-btn icon :class="item.is_week_end ? 'theme--dark' : ''">
                  <v-icon>{{ item.is_show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr v-if="item.is_show" :class="item.is_week_end ? 'week_end_style_inverse' : ''">
              <td colspan="7" class="hourlist_custom_padding">
                <template v-if="item.hours && item.hours.length > 0">
                  <v-card class="mb-2" outlined v-for="(hour, i) in item.hours" :key="i" @click="isClickable ? editSingleRecord(hour.id) : null">
                    <v-row class="mx-0 custom_row_color" v-if="hour.type === constants.WORKING">
                      <v-col cols="9" class="px-1 py-1">
                        <v-toolbar-title class="subtitle-2 pl-1 normal_white_space">{{ hour.project_name }}</v-toolbar-title>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="3" class="px-1 py-1">
                        <v-toolbar-title class="subtitle-2 px-0 font-weight-regular normal_white_space" v-if="isSameDays(hour)">{{`(${setFromTillHours(hour)})`}}</v-toolbar-title>
                      </v-col>
                    </v-row>
                    <v-list-item-content class="pa-1 pl-2">
                      <v-row class="mx-0">
                        <v-col cols="7" class="pa-0">
                          <v-list-item-title class="mb-1 body-2">{{ hour.activity_name }}</v-list-item-title>
                          <v-list-item-subtitle class="body-2">{{ hour.worktype_name || ''}}</v-list-item-subtitle>
                          <v-list-item-subtitle class="body-2" v-if="hour.type === constants.NON_WORKING && isSameDays(hour) && hasHourValues(hour)">{{`(${setFromTillHours(hour)})`}}</v-list-item-subtitle>
                        </v-col>
                        <v-col cols="5" class="pa-0 text-right" :class="hour.type === constants.NON_WORKING ? 'pt-1' : ''">
                          <p class="pa-0 pb-1 ma-0 font-weight-medium">{{ getStatusText(hour.state) }}</p>
                          <v-chip small :color="getStatusColor(hour.state)" :text-color="getStatusForeColor(hour.state)">{{ hour.amount ? getBreakTimeReducedHour(hour) : '0,0' }}</v-chip>
                        </v-col>
                      </v-row>
                  </v-list-item-content>
                </v-card>
                </template>
                <template v-else>
                  <div class="list--items">
                    <v-flex sm12 class="pt-1 pb-1 pr-2 pl-2">
                      <h3 class="text-center">{{$t('message.common.noResults')}}</h3>
                    </v-flex>
                  </div>
                </template>
              </td>
            </tr>
          </table>
        </div>
      </v-card-text>
    </v-card>
    <v-row class="mt-5 float-right" v-if="false"><!-- remove this -->
      <v-speed-dial v-model="fab" right direction="left" transition="slide-y-transition" bottom="bottom" fixed class="timer_speed_dial_position">
        <template v-slot:activator>
          <v-btn small v-model="fab" color="secondary" dark fab>
            <v-icon v-if="!fab">mdi-alarm</v-icon>
            <v-icon v-else>mdi-close</v-icon>
          </v-btn>
        </template>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="accent"  fab small  @click="deliverHoursHandler" v-on="on">
              <v-icon>mdi-check-circle-outline</v-icon>
              </v-btn>
          </template>
          <span>Deliver</span>
        </v-tooltip>
        <v-btn fab dark small color="secondary" @click="openFormHandler()">
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-row>
  </v-card>
</template>
<script>
/* eslint-disable */
import moment from 'moment'
import { mapGetters } from 'vuex'
import CONSTANTS from '@/assets/json/constants.json'
// import { listOfFormTypes } from '../../utils/support_list'
export default {
  data () {
    return {
      MODULE: 'hours',
      positionX: 0,
      positionY: 0,
      customWeek: moment().isoWeek(),
      customYear: moment().isoWeekYear(),
      listOfDays: [],
      projectsForComputed: [],
      listOfProjects: [],
      localeLang: 'no',
      startOfWeek: 0,
      endOfWeek: 0,
      listOfHourEntries: [],
      getHolidays: [],
      showNormalHourMenu: false,
      hourObj: {
        id: 0,
        datePicker: null,
        projectid: null
      },
      monthName: '',
      activeUser: {},
      /* data for timer pwa design change */
      fieldsLoading: false,
      hoursListWrapper: [],
      totalHoursInWeek: 0,
      fab: false,
      constants: CONSTANTS,
      loading: false,
      reloadApp: false
    }
  },
  async beforeRouteEnter (to, from, next) {
    await next(vm => {
      if (from && from.path === '/login') vm.reloadApp = true
    })
  },
  beforeMount () {
    if (this.$i18n.locale === 'no') this.localeLang = 'nb'
    else this.localeLang = 'en'
    var weekNo = +window.localStorage.getItem('TimerPwa_weekno')
    var year = +window.localStorage.getItem('TimerPwa_year')
    if (weekNo && year) {
      this.startOfWeek = moment().isoWeekYear(year).isoWeek(weekNo).startOf('isoweek')
      this.endOfWeek = moment().isoWeekYear(year).isoWeek(weekNo).endOf('isoweek')
    } else {
      this.startOfWeek = moment().isoWeekYear(moment().isoWeekYear()).isoWeek(moment().isoWeek()).startOf('isoweek')
      this.endOfWeek = moment().isoWeekYear(moment().isoWeekYear()).isoWeek(moment().isoWeek()).endOf('isoweek')
      this.setValuesInLocalStorage(moment())
    }
  },
  created () {
    this.$store.dispatch('getGeneralSettings')
    this.$store.dispatch('getWorktypes').then(() => {
      this.setDefaultValues({ prop: 'work_id', list: this.getWorktypes })
    })
    this.$store.dispatch('getActivities').then(() => {
      this.setDefaultValues({ prop: 'activity_id', list: this.getActivities })
    })
  },
  computed: {
    ...mapGetters(['getWorktypes', 'getuserDetails', 'getActivities']), /* getUsers */
    loadDays () {
      this.listOfDays = []
      this.loopWeek = this.startOfWeek
      this.customWeek = moment(this.endOfWeek).isoWeek()
      this.customYear = moment(this.endOfWeek).isoWeekYear()
      for (let i = 1; i <= 7; i++) {
        // let obj = holidaysData.find(x=> x.date === moment(this.loopWeek).format('YYYY-MM-DDTHH:mm:ss'))
        // const loopingDateString = moment(this.loopWeek).format('YYYY-MM-DDTHH:mm:ss')
        this.listOfDays.push({
          short_day: moment(this.loopWeek).locale(this.localeLang).format('ddd'),
          date: moment(this.loopWeek).format('DD.MM.YYYY'),
          display_date: moment(this.loopWeek).format('DD'),
          monthName: moment(this.loopWeek).format('MMMM'),
          is_show: false,
          is_today: moment(moment(this.loopWeek)).isSame(moment(), 'day'),
          is_week_end: [6, 0].includes(moment(this.loopWeek).day())
        })
        this.loopWeek = this.startOfWeek.clone().add(i, 'd')
      }
      this.monthName = this.listOfDays[0].monthName
      const lastDateMonthName = this.listOfDays[this.listOfDays.length - 1].monthName
      if (this.listOfDays[0].monthName !== lastDateMonthName) this.monthName += `-${lastDateMonthName}`
      this.setValuesInLocalStorage(moment(this.endOfWeek))
    },
    weekValidation () {
      return [
        val => /^[0-9]+?[0-9]*$/.test(val) || this.$t('message.calendar.weekNotExists'),
        val => val <= 53 || this.$t('message.calendar.weekNotExists')
      ]
    },
    yearValidation () {
      return [
        v => (v || 0).toString().length === 4 || this.$t('message.calendar.yearValid'),
        v => v && v > 1920 || this.$t('message.calendar.yearTooLessValidation'),
        val => /^[0-9]+?[0-9]*$/.test(val) || this.$t('message.calendar.valid')
      ]
    },
    hoursList: {
      get () {
        this.hoursListWrapper = this.$formatter.cloneVariable(this.constructCalendarData())
        return this.hoursListWrapper
      },
      set (value) {
        this.hoursListWrapper = value
      }
    },
    subItemHeaders () {
      return [{
        value: 'project_name',
        width: '45%'
      }, {
        value: 'worktype_name',
        width: '5%'
      }, {
        value: 'state',
        width: '50%'
      }]
    },
    isClickable () {
      return !this.$store.state.common.isCalendarFormExcluded
    }
  },
  watch: {
    '$i18n.locale' (val) {
      if (this.$i18n.locale === 'no') this.localeLang = 'nb'
      else this.localeLang = 'en'
      this.loadDays
    },
    reloadApp (val) {
      if (val) this.$router.go()
    }
  },
  mounted () {
    const getActiveuser = this.$cookie.get(process.env.VUE_APP_USER)
    this.activeUser = getActiveuser ? JSON.parse(getActiveuser) : {}
    this.setProjects()
    const text = 'message.common.deleteSuccess'
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.MODULE) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        this.getAllHourEntries()
      }
    })
    this.$eventBus.$on('deliverHoursHandler', () => {
      this.deliverHoursHandler()
    })
  },
  methods: {
    setProjects () {
      this.loading = true
      this.$api.execute('get', 'projects/get_as_simplified')
        .then((response) => {
          this.listOfProjects = response.data
          this.getAllHourEntries()
        })
    },
    prevWeek () {
      this.startOfWeek = moment(this.startOfWeek).subtract(1, 'weeks')
      this.endOfWeek = moment(this.endOfWeek).subtract(1, 'weeks')
      this.getAllHourEntries()
    },
    nextWeek () {
      this.startOfWeek = moment(this.startOfWeek).add(1, 'weeks')
      this.endOfWeek = moment(this.endOfWeek).add(1, 'weeks')
      this.getAllHourEntries()
    },
    goToToday () {
      this.startOfWeek = moment().isoWeekYear(moment().isoWeekYear()).isoWeek(moment().isoWeek()).startOf('isoweek')
      this.endOfWeek = moment().isoWeekYear(moment().isoWeekYear()).isoWeek(moment().isoWeek()).endOf('isoweek')
      this.getAllHourEntries()
    },
    goToCustomWeek () {
      if (this.$refs.weekNavigation.validate()) {
        this.startOfWeek = moment().isoWeekYear(+this.customYear).isoWeek(this.customWeek).startOf('isoweek')
        this.endOfWeek = moment().isoWeekYear(+this.customYear).isoWeek(this.customWeek).endOf('isoweek')
        this.getAllHourEntries()
      }
    },
    getAllHourEntries () {
      // show loader and hide
      this.loading = true
      const start = this.startOfWeek.format('MM.DD.YYYY')
      const end = moment(this.startOfWeek).add('7', 'days').add('-1', 'seconds').format('MM.DD.YYYY HH:mm:ss')
      const model = { start: start, end: end, showAllProjects: true, users: [this.getuserDetails.id] }
      this.$api.execute('post', `${this.MODULE}/filter`, model)
        .then((response) => {
          const appHourEntries = this.$formatter.cloneVariable(response.data)
          const finalList = []
          appHourEntries.forEach(element => {
            if (element.type === CONSTANTS.NON_WORKING) {
              const startDate = moment(element.start_date, 'DD.MM.YYYYTHH:mm:ss')
              const endDate = moment(element.end_date, 'DD.MM.YYYYTHH:mm:ss')
              const datesArray = this.enumerateDaysBetweenDates(startDate, endDate)
              if (datesArray.length > 1) {
                datesArray.forEach(item => {
                  const startDate = this.$formatter.formatDate(item, 'DD.MM.YYYY', 'DD.MM.YYYYTHH:mm:ss')
                  const endDate = this.$formatter.formatDate(item, 'DD.MM.YYYY', 'DD.MM.YYYYTHH:mm:ss')
                  finalList.push({ ...element, start_date: startDate, end_date: endDate })
                })
              } else {
                finalList.push(element)
              }
            } else {
              finalList.push(element)
            }
          })
          this.listOfHourEntries = this.$formatter.cloneVariable(finalList)
          this.loadDays
        })
        .finally(() => {
          this.loading = false
        })
    },
    enumerateDaysBetweenDates (startDate, endDate) {
      var now = startDate; var dates = []
      while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('DD.MM.YYYY'))
        now.add(1, 'days')
      }
      return dates
    },
    setDefaultValues (data) {
      const { list, prop } = data
      const item = list.find(x => x.isdefault)
      if (item) {
        const model = Object.assign({ ...this.hourObj }, { [prop]: item.id })
        this.hourObj = model
      }
    },
    openFormHandler () {
      this.showNormalHourMenu = false
      event.stopPropagation()
      this.showNormalHourMenu = true
      this.positionX = event.clientX
      this.positionY = event.clientY

      this.hourObj.id = 0
      this.hourObj.state = null
      this.hourObj.date = moment().format('DD.MM.YYYY')
      this.hourObj.userid = this.activeUser.id ? this.activeUser.id : ''
    },
    getSingleRecordHandler (id) {
      this.$api.getSingleRecordHandler(`${this.MODULE}/${id}`)
        .then((result) => {
          let hour = result.data
          hour.hours = parseFloat(hour.hours).toFixed(2)
          hour.datePicker = null
          hour.projectmemberid = hour.userid
          const transformNumber = { number: { list: ['hours'], format: 'replaceDotWithComma' } }
          hour = this.$formatter.formatModel(hour, transformNumber)
          const transformData = { date: { list: ['date'], from: 'DD.MM.YYYYTHH:mm:ss', to: 'DD.MM.YYYY' } }
          const model = this.$formatter.formatModel(hour, transformData)
          if (result.data) this.hourObj = model
          this.showNormalHourMenu = true
        })
    },
    deliverHoursHandler () {
      let hours = this.$formatter.cloneVariable(this.listOfHourEntries)
      hours = hours.filter(x => x.state === CONSTANTS.NOT_DELIVERED)
      const hourIds = hours.map(x => x.id)
      const model = { ids: hourIds, parameters: { state: CONSTANTS.DELIVERED } }
      if (this.$formatter.isArrayHasData(hourIds)) {
        this.$api.execute('put', `${this.MODULE}/bulk_update`, model)
          .then((response) => {
            this.getAllHourEntries()
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.timer.deliveredMsg' })
          })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.timer.noHours' })
    },
    getHoursSum (hours) {
      const totalInWork = this.$formatter.getSumBy(hours.filter(x => x.type === CONSTANTS.WORKING), x => x.amount)
      const totalInAbsence = this.$formatter.getSumBy(hours.filter(x => x.type === CONSTANTS.NON_WORKING), x => x.amount)
      return `${this.$formatter.replaceDotWithComma(totalInWork)}(${this.$formatter.replaceDotWithComma(totalInAbsence)})t`
    },
    getStatusColorHandler (hourList) {
      if (hourList.some(item => item.state === CONSTANTS.NOT_DELIVERED)) return 'not_delivered'
      else if (hourList.some(item => item.state === CONSTANTS.NOT_APPROVED)) return 'not_approved'
      else if (hourList.some(item => item.state === CONSTANTS.DELIVERED)) return 'delivered'
      else if (hourList.some(item => item.state === CONSTANTS.APPROVED)) return 'approved'
    },
    setValuesInLocalStorage (week) {
      window.localStorage.setItem('TimerPwa_weekno', week.isoWeek())
      window.localStorage.setItem('TimerPwa_year', week.isoWeekYear())
    },
    /* pwa design data methods added */
    constructCalendarData () {
      const listOfHours = this.$formatter.cloneVariable(this.listOfHourEntries)
      const listOfDays = this.$formatter.cloneVariable(this.listOfDays)
      var totalInWeek = 0
      for (let i = 0; i < listOfDays.length; i++) {
        var total = 0
        const dayHours = listOfHours !== null ? listOfHours.filter(x => this.$formatter.formatDate(x.start_date, 'DD.MM.YYYYTHH:mm:ss', 'DD.MM.YYYY') === listOfDays[i].date) : []
        const groupedHours = this.$formatter.groupBy(dayHours, 'type')
        const types = [CONSTANTS.WORKING, CONSTANTS.NON_WORKING]
        let newHours = []
        types.forEach(type => {
          if (groupedHours[type] && groupedHours[type].length > 0) newHours = [...newHours, ...groupedHours[type]]
        })
        listOfDays[i].hours = newHours
        /* from hms */
        listOfDays[i].totalInWork = 0
        listOfDays[i].totalInAbsense = 0
        listOfDays[i].startingTime = 0
        listOfDays[i].endingTime = 0
        const listStartTime = []; const listOfEndTime = []
        let totalInWork = 0; let totalInAbsense = 0
        /* from hms */
        for (let j = 0; j < listOfDays[i].hours.length; j++) {
          const hourItem = listOfDays[i].hours[j]
          totalInWeek += hourItem.amount ? hourItem.amount : 0
          totalInWeek -= hourItem.break_hr ? parseFloat(hourItem.break_hr) : 0
          listOfDays[i].hours[j].project_name = this.getProjectDetails(hourItem.project_id)
          listOfDays[i].hours[j].worktype_name = this.getWorkTypeName(hourItem.work_id)
          listOfDays[i].hours[j].activity_name = this.getActivityName(hourItem.activity_id)
          total += listOfDays[i].hours[j].amount

          /* get starting and ending time */
          if (this.$formatter.formatDate(listOfDays[i].hours[j].start_date, 'DD.MM.YYYYT00:00:00', 'DD.MM.YYYY') === this.$formatter.formatDate(listOfDays[i].hours[j].end_date, 'DD.MM.YYYYT00:00:00', 'DD.MM.YYYY')) {
            listStartTime.push(moment(listOfDays[i].hours[j].start_date, 'DD.MM.YYYY[T]HH.mm.ss').format('HH.mm'))
            listOfEndTime.push(moment(listOfDays[i].hours[j].end_date, 'DD.MM.YYYY[T]HH.mm.ss').format('HH.mm'))
          }
          listOfDays[i].startingTime = Math.min(...listStartTime)
          listOfDays[i].endingTime = Math.max(...listOfEndTime)
          /* get totalWork and totalAbsence hours */
          if (listOfDays[i].hours[j].type === CONSTANTS.WORKING) {
            totalInWork += listOfDays[i].hours[j].amount - listOfDays[i].hours[j].break_hr
          }
          if (listOfDays[i].hours[j].type === CONSTANTS.NON_WORKING) {
            totalInAbsense += listOfDays[i].hours[j].amount
          }
          if (!isNaN(listOfDays[i].hours[j].break_hr)) {
            totalInAbsense += listOfDays[i].hours[j].break_hr
          }
          listOfDays[i].totalInWork = totalInWork
          listOfDays[i].totalInAbsense = totalInAbsense
          listOfDays[i].hours[j].break_hr = this.$formatter.replaceDotWithComma(listOfDays[i].hours[j].break_hr)
          listOfDays[i].hours[j].startTime = this.$formatter.formatDate(listOfDays[i].hours[j].start_date, 'DD.MM.YYYY HH:mm:ss', 'HH:mm')
          listOfDays[i].hours[j].endTime = this.$formatter.formatDate(listOfDays[i].hours[j].end_date, 'DD.MM.YYYY HH:mm:ss', 'HH:mm')
          if (listOfDays[i].hours[j] && listOfDays[i].hours[j].state) {
            switch (listOfDays[i].hours[j].state) {
              case 'approved':
                listOfDays[i].statusName = 'APPROVED'
                break
              case 'not_approved':
                listOfDays[i].statusName = 'NOT_APPROVED'
                break
              case 'delivered':
                listOfDays[i].statusName = 'DELIVERED'
                break
              case 'not_delivered':
                listOfDays[i].statusName = 'NOT_DELIVERED'
                break
            }
          }
        }
        listOfDays[i].total = total
      }
      this.totalHoursInWeek = totalInWeek || 0
      /* for (let i = 0; i < listOfDays.length; i++) {
        this.totalHoursInWeek += listOfDays[i].total
      } */
      return listOfDays
    },
    getStatusText (status) {
      if (!status) return ''
      switch (status) {
        case 'approved':
          return this.$t('message.timer.approved')
        case 'not_approved':
          return this.$t('message.timer.notApproved')
        case 'delivered':
          return this.$t('message.timer.delivered')
        case 'not_delivered':
          return this.$t('message.timer.notDelivered')
        default:
          break
      }
    },
    getStatusColor (status) {
      if (status) {
        if (status === 'delivered') return 'rgba(253, 227, 3, 0.42) !important'
        else if (status === 'not_delivered') return '#eaeaea !important'
        else if (status === 'approved') return '#11a911 !important'
        else if (status === 'not_approved') return '#F08080 !important'
        else return ''
      } else return ''
    },
    getStatusForeColor (status) {
      if (status) {
        if (status === 'delivered') return 'black'
        else if (status === 'not_delivered') return 'black'
        else if (status === 'approved') return 'white'
        else if (status === 'not_approved') return 'white'
        else return ''
      } else return ''
    },
    getBreakTimeReducedHour (item) {
      let hour = item.amount
      if (item.break_hr) hour -= parseFloat(this.$formatter.replaceCommaWithDot(item.break_hr))
      return hour ? this.$formatter.replaceDotWithComma(hour) : '0,00'
    },
    setFromTillHours (hour) {
      let hours = ''
      hours += moment(hour.start_date, 'DD.MM.YYYYTHH:mm:ss').format('HH:mm')
      hours += ` - ${moment(hour.end_date, 'DD.MM.YYYYTHH:mm:ss').format('HH:mm')}`
      return hours
    },
    hasHourValues (hour) {
      const startTime = moment(hour.start_date, 'DD.MM.YYYYTHH:mm:ss').format('HH:mm')
      const endTime = moment(hour.end_date, 'DD.MM.YYYYTHH:mm:ss').format('HH:mm')
      return startTime === '00:00' && endTime === '00:00' ? false : true
    },
    getProjectDetails (projectId) {
      if (!projectId) return ''
      const project = this.listOfProjects.find(x => x.id === projectId)
      if (project) {
        return `${project.number || ''} ${project.po_name || ''} - ${project.name} ${project.ao_name || ''}`
      }
    },
    getWorkTypeName (id) {
      if (id && this.getWorktypes.length > 0) {
        const result = this.getWorktypes.find(x => x.id === id)
        return result ? this.$i18n.locale === 'no' ? result.no_name : result.en_name : ''
      } else return ''
    },
    getActivityName (id) {
      if (id && this.getActivities.length > 0) {
        const result = this.getActivities.find(x => x.id === id)
        return result ? result.name : ''
      } else return ''
    },
    isSameDays (event) {
      return moment(event.start_date, ['DD.MM.YYYY', 'MM.DD.YYYY']).isSame(moment(event.end_date, ['DD.MM.YYYY', 'MM.DD.YYYY']))
    },
    editSingleRecord (id) {
      // var model = { logs: logsOnDay, totalHourLog: totalInWork, totalNonbillable: totalInAbsense, timings: { start, end } }
      /* this.$store.dispatch('showAllTheLogsOnDay', model) */
      this.$router.push(`/calendar/actions/${id}`)
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('deliverHoursHandler')
  }
}
</script>
<style>
.reduceHeight {
  height: 10px;
}
.delivered {
  background-color: #e0b517;
  color: white;
}
.not_delivered {
  background-color: lightgray !important
}
.not_approved {
  background-color: #ea1616;
  color: white;
}
.approved {
  background-color: rgba(86, 177, 62, 0.65);
  color: white;
}
.today_color {
  background-color: #2196f357 !important;
}
  .timer_module table {
    width: 100%;
    height: 50px;
    border: 1px solid whitesmoke;
    /* box-shadow: 4px 5px 15px whitesmoke; */
  }
  .timer_module .list--items {
    border: 1px solid #e0e0e0;
  }
  .sub_table_border {
    border: 1px solid whitesmoke;
  }
  .timer_module .v-form > .container > .layout:only-child {
    margin: 0 !important;
  }
  .timer--wrapper .v-speed-dial {
    position: absolute;
  }
  .day-locked {
    pointer-events: none;
    opacity: 0.6;
  }
  .timer_table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0px 3px !important;
  }
  .timer_speed_dial_position {
    bottom: 9vh;
    right: 1vh;
  }
  .custom_padding > div.v-toolbar__content {
    padding: 0px !important;
  }
  .week_end_style {
    border-collapse: collapse;
    padding: 0 2px;
    background: #f4ced2;/* #578ebe; */
    /* color: #fff; */
  }
  .week_end_style_inverse {
    background: #fff;
    /* color: rgba(0, 0, 0, 0.6); */
  }
  .hourlist_custom_padding {
    padding: 3px !important;
  }
  .normal_white_space {
    white-space: normal !important;
  }
  .custom_row_color {
    background: #d7eafa;    
  }
</style>
